<template>
  <v-container
    class="profile-update-container scroll-container"
    v-if="userData"
  >
    <CategoryTitle :category="category" class="mb-3" />

    <h5
      class="text-uppercase font-weight-bold"
      v-html="$t('profile.update.personalData')"
    />
    <!-- PERSONAL DATA - NOT MODIFIABLE -->

    <UserData
      v-if="userData"
      ref="personalDataForm"
      :userData.sync="userData"
      :supportData.sync="supportData"
      :hasFidelityCard="true"
      :askFirstName="true"
      :askLastName="true"
      :askFiscalCode="true"
      :isProfileForm="true"
      :showButtons="false"
      :showFiscalCode="true"
      :isFieldDisabled="true"
      :fiscalCodeDisabled="fiscalCodeDisabled"
      @validate="validate"
    />

    <h5
      class="text-uppercase font-weight-bold"
      v-html="$t('profile.update.residenceData')"
    />
    <UserAddress
      v-if="userData"
      ref="addressDataForm"
      :userData.sync="userData"
      :supportData.sync="supportData"
      :isFieldDisabled="true"
      :isProfileForm="true"
      :showButtons="false"
    />
    <ResponseMessage class="mt-3" :response="response" />
    <!-- UPDATE PERSONAL DATA -->
    <v-col class="pa-0 ml-auto" cols="12" md="5">
      <v-btn
        class="text-uppercase"
        color="primary"
        x-large
        depressed
        block
        v-html="$t('profile.updatePersonalData')"
        @click="validate"
      />
    </v-col>

    <!-- UPDATE PASSWORD -->
    <h5
      class="text-uppercase font-weight-bold px-0 pt-10 pt-md-0"
      v-html="`${$t('profile.edit.password')} *`"
    />
    <v-form ref="passwordform" v-model="isPasswordFormValid">
      <v-row class="my-3">
        <v-col cols="12" sm="5">
          <!-- password -->
          <v-text-field
            v-model="oldPassword"
            :label="`${$t('profile.oldPassword')}`"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          />
        </v-col>
        <v-col cols="0" sm="5"> </v-col>
        <v-col cols="12" sm="5">
          <!-- password -->
          <v-text-field
            v-model="password"
            :label="`${$t('profile.password')}`"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            :hint="`${$t('profile.passwordInfo')}`"
            required
            outlined
            dense
          />
        </v-col>
        <v-col cols="12" sm="5">
          <!-- conferma password -->
          <v-text-field
            :label="`${$t('profile.passwordConfirm')}`"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordConfirmRules"
            @click:append="toggleShowPassword"
            :hint="`${$t('profile.passwordInfo')}`"
            required
            outlined
            dense
          />
        </v-col>
      </v-row>
      <v-col class="pa-0 ml-auto" cols="12" md="5">
        <v-btn
          class="text-uppercase"
          color="primary"
          x-large
          depressed
          block
          :disabled="!isPasswordFormValid"
          @click="updatePassword()"
          :loading="loadingPasswordChange"
        >
          {{ $t("profile.updatePassword") }}
        </v-btn>
      </v-col>
    </v-form>

    <!-- UPDATE PRIVACY CONSENTS -->
    <h5
      class="text-uppercase font-weight-bold px-0 pt-10 pt-md-0"
      v-html="`${$t('profile.edit.privacy')} *`"
    />
    <v-form
      ref="disclaimerform"
      v-model="isDisclaimerFormValid"
      lazy-validation
    >
      <RegistrationModuleDisclaimer
        v-if="registrationModules"
        :disclaimerList="registrationModules"
        :options="disclaimerOptions"
        @disclaimerChanged="disclaimerChanged"
      />
      <div class="d-flex justify-center justify-md-end mt-3 mt-sm-6">
        <v-btn
          color="primary"
          large
          elevation="0"
          :disabled="!isDisclaimerFormValid"
          @click="updateDisclaimer()"
          :loading="loadingDisclaimerChange"
        >
          {{ $t("profile.updatePrivacy") }}
        </v-btn>
      </div>
    </v-form>

    <!-- DELETE ACCOUNT -->
    <div class="anonymize-block mt-10 px-0 py-2 py-md-0">
      <h3 v-html="$t('profile.anonymizeProfileDialog.title')" />
      <div v-html="$t('profile.anonymizeProfileDialog.text')" />

      <ResponseMessage class="mt-3" :response="responseAnonymazeAccount" />
      <div
        no-gutters
        class="d-flex justify-center justify-md-start mt-3 mt-sm-6"
      >
        <v-btn
          large
          color="primary"
          depressed
          min-width="250"
          class="text-uppercase mb-2"
          @click="anonymizeAccount()"
          :loading="loadingAnonymizeAccount"
          >{{ $t("profile.anonymizeAccountBtn") }}
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="removeDialog" max-width="400">
      <div class="vuedl-layout__closeBtn" @click="removeDialog = false">
        ×
      </div>
      <v-card>
        <v-card-title class="anonymize-dialog-title text-h2">
          {{ $t("profile.anonymizeProfileDialog.title") }}
        </v-card-title>
        <v-card-text
          class="anonymize-dialog-text text-body-1"
          style="font-size: 14px !important"
          v-html="$t('profile.anonymizeProfileDialog.text')"
        >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            outlined
            @click.prevent="anonymizeAccount(true)"
            @mousedown.stop
          >
            {{ $t("common.yes") }}
          </v-btn>
          <v-btn class="primary" depressed text @click="removeDialog = false">
            {{ $t("common.no") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style lang="scss">
.profile-update-container {
  h2 {
    font-size: 30px;
  }
  a {
    color: var(--v-default-base);
  }
  .v-autocomplete {
    box-shadow: none;
    -webkit-box-shadow: none;
    input {
      font-size: 16px !important;
    }
  }
}
</style>
<script>
import {
  requiredValue,
  isEmail,
  isNumber,
  minLength,
  minCapitalCharacter,
  minSymbolCharacter
} from "~/validator/validationRules";

import CategoryTitle from "@/components/category/CategoryTitle.vue";
import UserData from "@/components/profile/form/UserData.vue";
import UserAddress from "@/components/profile/form/UserAddress.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";
import categoryMixins from "~/mixins/category";
//import { setPersonInfo } from "~/service/ebsn";
import AliRegistrationService from "@/service/aliRegistrationService";
import UserService from "~/service/userService";
import RegistrationModuleDisclaimer from "@/commons/components/disclaimer/RegistrationModuleDisclaimer.vue";
import GeoService from "~/service/geoService";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import userService from "~/service/userService";

export default {
  name: "ProfileUpdate",
  components: {
    CategoryTitle,
    UserData,
    UserAddress,
    ResponseMessage,
    RegistrationModuleDisclaimer
  },
  mixins: [categoryMixins],
  directives: { mask },
  data() {
    return {
      isValid: true,
      birthdateMenu: false,
      showPassword: false,
      oldPassword: null,
      loadingPasswordChange: false,
      password: null,
      disclaimerList: null,
      userDisclaimer: null,
      registrationModules: null,
      isDisclaimerFormValid: false,
      loadingDisclaimerChange: false,
      disclaimerToChange: null,
      disclaimerOptions: {
        color: "primary"
      },
      requiredRules: requiredValue,
      requiredValue: requiredValue,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      dateRules: [requiredValue("Digitare la data di nascita")],
      isEmail: isEmail,
      isNumber: isNumber,
      fidelityCardRules: [
        requiredValue(),
        isNumber("Deve contenere solo cifre")
      ],
      passwordRules: [
        requiredValue("Digitare la password"),
        minLength(
          8,
          "La password deve essere lunga almeno 8 caratteri e può contenere solo lettere, numeri o i caratteri @#$%^&+*!="
        ),
        minCapitalCharacter(),
        minSymbolCharacter()
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.password || "Le password non coincidono"
      ],
      userData: null,
      supportData: {
        privacy1: true,
        privacy2: true,
        country: {
          itemId: null
        }
      },
      fiscalCodeDisabled: false,
      billingAddress: {},
      provinces: [],
      response: {},
      loadingAnonymizeAccount: false,
      removeDialog: false,
      responseAnonymazeAccount: {},
      isPasswordFormValid: false
    };
  },
  computed: {
    birthDate: {
      get: function() {
        if (this.userData.person.birthDate) {
          return this.$dayjs(this.userData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.userData.person.birthDate = this.$dayjs(
          value,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }
    }
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout",
      loadCart: "cart/loadCart"
    }),
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    async getUserData() {
      const result = await UserService.getUserDetail();
      if (result) {
        this.userData = result;
        this.fiscalCodeDisabled = this.userData.person?.fiscalCode
          ? true
          : false;
        // workaround per forzare il popolamento del campo country
        /* let country = global.config.customCountries.find(c => {
          return c.itemId === this.userData.billingAddress.countryId;
        });*/
        //this.$refs.addressDataForm.changeCountry(country, false);
        //this.loadDisclaimer();
      }
    },
    async validate() {
      let personalData = this.$refs.personalDataForm.$refs.form;
      let addressData = this.$refs.addressDataForm.$refs.form;
      this.response = {};

      if (personalData.validate() && addressData.validate()) {
        if (this.userData.defaultStoreAddressId) {
          const wId = this.userData.defaultStoreAddressId?.warehouseId;
          if (wId) {
            this.userData.defaultStoreAddress = { addressId: wId };
          }
          delete this.userData.defaultStoreAddressId;
        }

        const userAddressCoords = await GeoService.geocodeAddress(
          this.userData.billingAddress
        );
        this.userData.billingAddress.gps_latitude =
          userAddressCoords.gpsLatitude;
        this.userData.billingAddress.gps_longitude =
          userAddressCoords.gpsLongitude;

        this.updateProfile();
      } else {
        this.response = {
          errors: [
            {
              error: this.$t("common.incorrectFormMessage")
            }
          ]
        };
      }
    },
    back() {
      this.$router.back();
    },
    async updateProfile() {
      let _this = this;
      if (typeof this.userData.billingAddress.province === "object") {
        this.userData.billingAddress.province = this.userData.billingAddress.province.name;
      }
      try {
        _this.response = {};
        let res = await AliRegistrationService.updateUserDetail(_this.userData);
        if (res) {
          await this.loadCart();
        }
      } catch (response) {
        _this.response = response;
      }
    },
    async updatePassword() {
      let _this = this;
      try {
        _this.response = {};
        _this.loadingPasswordChange = true;
        if (_this.$refs.passwordform.validate()) {
          let res = await userService.updatePassword(
            _this.oldPassword,
            _this.password
          );
          _this.loadingPasswordChange = false;
          if (res) {
            _this.loadCart();
          }
        }
      } catch (response) {
        _this.response = response;
      } finally {
        _this.loadingPasswordChange = false;
      }
    },
    async updateDisclaimer() {
      let vm = this;
      try {
        vm.loadingDisclaimersChange = true;
        if (vm.$refs.disclaimerform.validate()) {
          if (vm.disclaimerToChange) {
            let registrationModulesParam = vm.disclaimerToChange.map(
              disclaimer => {
                let obj = {
                  registrationDisclaimer: {
                    registrationDisclaimerId:
                      disclaimer.registrationDisclaimerId
                  },
                  value: disclaimer.value
                };
                if (disclaimer.registrationModuleDisclaimerId) {
                  obj.registrationModuleDisclaimerId =
                    disclaimer.registrationModuleDisclaimerId;
                }
                return obj;
              }
            );

            if (vm.userDisclaimer && vm.userDisclaimer.registrationModules) {
              vm.userDisclaimer.registrationModules[0].registrationModuleDisclaimers = registrationModulesParam;
            } else {
              vm.userDisclaimer = {
                registrationModules: [
                  {
                    registrationModuleAction: {
                      registrationModuleActionId: 1,
                      name: "Inserimento"
                    },
                    registrationModuleType: {
                      registrationModuleTypeId: 2,
                      name: "REG_WEB",
                      description: ""
                    },
                    addDate: new Date().toISOString(),
                    registrationModuleDisclaimers: registrationModulesParam
                  }
                ]
              };
            }

            let result = await AliRegistrationService.updateUserDisclaimer(
              vm.userDisclaimer.registrationModules[0]
            );
            if (
              result &&
              result.registrationModules &&
              result.registrationModules.length > 0
            ) {
              this.userDisclaimer = result;
            }

            vm.loadingDisclaimersChange = false;
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        vm.loadingDisclaimersChange = false;
      }
    },
    async loadDisclaimer() {
      let storeId = 5;
      let registrationModuleTypeId = 2;
      let res = await UserService.getRegistrationDisclaimerList(
        registrationModuleTypeId,
        storeId
      );
      const disclaimers = res.registrationDisclaimers;
      this.disclaimerList = disclaimers.sort((a, b) => a.priority - b.priority);
    },
    async getDisclaimerList() {
      let vm = this;
      let result = await UserService.getRegistrationDisclaimerList(2, 5);
      if (result && result.registrationDisclaimers) {
        vm.disclaimerList = result.registrationDisclaimers;
      }
    },
    /* disclaimerChanged(registrationModules) {
      this.registrationModules = registrationModules;
    },*/
    disclaimerChanged(userDisclaimer) {
      this.disclaimerToChange = userDisclaimer;
    },
    async getUserDisclaimer(registrationModuleTypeId, storeId) {
      let result = await UserService.getUserDisclaimerListByType(
        registrationModuleTypeId,
        storeId
      );
      if (
        result &&
        result.registrationModules &&
        result.registrationModules.length > 0 &&
        result.registrationModules[0].registrationModuleDisclaimers
      ) {
        this.userDisclaimer = result;

        result.registrationModules[0].registrationModuleDisclaimers.forEach(
          disclaimer => {
            let disclaimerFromList = this.disclaimerList.find(
              item =>
                item.registrationDisclaimerId ===
                disclaimer.registrationDisclaimer.registrationDisclaimerId
            );
            if (disclaimerFromList) {
              disclaimerFromList.value = disclaimer.value;
              disclaimerFromList.registrationModuleDisclaimerId =
                disclaimer.registrationModuleDisclaimerId;
            }
          }
        );
      }
      this.registrationModules = this.disclaimerList;
    },

    async anonymizeAccount(perfom = false) {
      this.removeDialog = true;
      if (perfom) {
        this.loadingAnonymizeAccount = true;
        let result = await UserService.anonymizeAccount();
        if (result.response.status === 0) {
          this.removeDialog = false;
          // se utente davvero anonimizzato -> logout
          await this.doLogout();
          this.loadingAnonymizeAccount = false;
          this.$router.push({ name: "Home" });
        } else {
          this.responseAnonymazeAccount = result.response;
          this.removeDialog = false;
          this.loadingAnonymizeAccount = false;
        }
      }
    }
  },
  async mounted() {
    await this.getUserData();
    await this.loadDisclaimer();
    //await userService.getUserDisclaimerListByType();
    await this.getUserDisclaimer();
  }
};
</script>
